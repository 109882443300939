.header_title {
  font-size: 3rem;
  font-weight: bold;

  margin-top: 2rem;

  text-transform: uppercase;
  text-align: center;
  color: #003fa6;

  width: 100%;

  padding: 2rem 1rem 0.5rem 1rem;
}

.bv3_manuais {
  text-align: center;
  text-transform: uppercase;

  font-weight: 600;
  font-size: 1rem;

  color: gray;
}

.sumario_container {
  /* background-color: red; */
  max-width: 80%;
  margin: 1rem auto;
  padding: 1rem;
}

.listItem {
  text-transform: uppercase;
  font-size: 1rem;
  margin-top: 2px;
  margin-bottom: 2px;

  text-align: left;

  max-width: 80%;

  font-weight: bold;
  color: #003fa6;
}

.listSubItem {
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 2px;
  margin-bottom: 2px;

  color: #003fa6;
}

.input {
  text-align: right;
  margin: 0.125rem 0;
  height: 60%;
  width: 40px;
}

.svg:hover {
  cursor: pointer;
  transform: scale(1.125);
}
